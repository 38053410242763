import React, { FC } from 'react';
import { Pressable, Image } from 'react-native';

interface IProps {
    onPress: CallableFunction;
}
const RewindButton: FC<IProps> = ({ onPress }) => {
    return (
        <Pressable onPress={onPress}>
            <Image
                style={{
                    width: 100,
                    height: 50,
                    resizeMode: 'contain',
                }}
                source={{
                    uri: "data:image/svg+xml,%3Csvg id='SvgjsSvg1001' width='288' height='288' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs'%3E%3Cdefs id='SvgjsDefs1002'%3E%3C/defs%3E%3Cg id='SvgjsG1008' transform='matrix(1,0,0,1,0,0)'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 512 512' viewBox='0 0 512 512' width='288' height='288'%3E%3Cpath d='M297.2,478l20.7-21.6L108.7,256L317.9,55.6L297.2,34L65.5,256L297.2,478z M194.1,256L425.8,34l20.7,21.6L237.3,256 l209.2,200.4L425.8,478L194.1,256z' fill='%2343454c' class='color000 svgShape'%3E%3C/path%3E%3C/svg%3E%3C/g%3E%3C/svg%3E",
                }}
            />
        </Pressable>
    );
};

export default RewindButton;
