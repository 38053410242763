import { useBackHandler, useDimensions } from '@react-native-community/hooks';
import React from 'react';
import { Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import ChannelsSelector from '../../components/ChannelsSelector/ChannelsSelector';
import EPGNavigator from '../../components/EPGNavigator/EPGNavigator';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    hideBottomOverlay,
    hideTopOverlay,
    showTopOverlay,
} from '../../state/store/ui';
import { colors, transparentColor } from '../../theming/colors';
import TopOverlay from '../Overlays/TopOverlay';
import StreamsPlayer from '../StreamsPlayer/StreamsPlayer';

const WelcomeScreen = () => {
    const dispatch = useAppDispatch();

    const window = Dimensions.get('window');

    const { availableChannels, currentlyWatchedChannel } = useAppSelector(
        state => state.stream,
    );
    const { bottomOverlayVisible, topOverlayVisible } = useAppSelector(
        state => state.ui,
    );
    const { width, height } = useDimensions().window;

    useBackHandler(() => {
        if (topOverlayVisible) {
            dispatch(hideTopOverlay());
            return true;
        }
        if (bottomOverlayVisible) {
            dispatch(hideBottomOverlay());
            return true;
        }
        return false;
    });

    return (
        <View
            style={{
                width: '100%',
                height: window.height,
                flexDirection: 'column',
            }}>
            <Pressable
                style={{
                    position: 'absolute',
                    borderRadius: 5,
                    bottom: 10,
                    zIndex: 100,
                    right: 30,
                    padding: 30,
                    backgroundColor: transparentColor('darkGrey', 0.6),
                    cursor: 'pointer',
                }}
                onPress={() => {
                    if (topOverlayVisible) {
                        dispatch(hideTopOverlay());
                    } else {
                        dispatch(showTopOverlay());
                    }
                }}>
                <Text style={{ color: colors.white, fontSize: 20 }}>EPG</Text>
            </Pressable>
            <View
                hasTVPreferredFocus={true}
                style={{
                    // width: '100%',
                    backgroundColor: colors.black,
                    flex: 1,
                    position: 'relative',
                }}>
                {currentlyWatchedChannel &&
                    currentlyWatchedChannel.info !== 'unknown' && (
                        <View style={styles.playerContainer}>
                            <StreamsPlayer></StreamsPlayer>
                            {/* <DummyPlayer></DummyPlayer> */}
                        </View>
                    )}
            </View>

            <View
                style={{
                    position: 'relative',
                    backgroundColor: colors.black,
                    padding: 20,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                    <ChannelsSelector
                        items={availableChannels}
                        currentlyWatchedChannel={
                            currentlyWatchedChannel
                        }></ChannelsSelector>
                </View>
            </View>
            {/* <BottomOverlay>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                    <ChannelsSelector
                        items={availableChannels}
                        currentlyWatchedChannel={
                            currentlyWatchedChannel
                        }></ChannelsSelector>
                </View>
            </BottomOverlay> */}

            {topOverlayVisible && (
                <TopOverlay>
                    <EPGNavigator
                        key={`epg-nav-${currentlyWatchedChannel.sources.id}`}></EPGNavigator>
                </TopOverlay>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    playerContainer: {
        backgroundColor: '#C9DAEA',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});

export default WelcomeScreen;
