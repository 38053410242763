import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ZaphodAuth } from '../../utils/zaphod/auth';

interface AuthState {
    access: string;
    refresh: string;
    payload: any;
    deviceId: string;
}

const authSlice = createSlice({
    name: 'auth',
    initialState: { access: '', refresh: '' } as AuthState,
    reducers: {
        auth: (state: AuthState, action: PayloadAction<ZaphodAuth>) => {
            state.access = action.payload.access;
            state.refresh = action.payload.refresh;
            state.payload = action.payload.payload;
        },

        setDeviceId: (state: AuthState, action: PayloadAction<string>) => {
            state.deviceId = action.payload;
        },
    },
});

export const { auth, setDeviceId } = authSlice.actions;
export default authSlice.reducer;
