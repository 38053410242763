import React from 'react';
import { SvgXml } from 'react-native-svg';
import { colors } from '../theming/colors';
import { Text } from 'react-native';

interface KPNLogoProps {
    width: number;
    color?: string;
}

const logoXmlTemplate = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 140">
    <path d="M111.955,60.706c-2.594-2.594-7.349-5.668-15.466-5.668a32.352,32.352,0,0,0-4.371.288l-.433.048.144.432a25.965,25.965,0,0,1,.865,4.035l.048.384.384-.048a26.841,26.841,0,0,1,3.363-.192c6.436,0,10.087,2.306,12.008,4.227a10.264,10.264,0,0,1,3.122,7.2c0,3.314-1.729,7.493-3.458,11.576a82.545,82.545,0,0,0-3.219,9.222c-1.585,6.293-4.226,16.812-10.519,16.812-2.065,0-4.515-1.153-7.445-3.459a26.332,26.332,0,0,1-5.043,2.21c4.707,4.179,8.694,6.148,12.488,6.148,10.183,0,13.5-13.353,15.275-20.51a59.322,59.322,0,0,1,2.978-8.5c1.873-4.515,3.842-9.174,3.842-13.449A15.3,15.3,0,0,0,111.955,60.706Z" fill="${color}"/>
    <path d="M90,51.147l.432-.1a40.584,40.584,0,0,1,4.371-.48h.433c-6.773-15.947-23.921-24.5-33.719-24.5-13.017,0-17.628,13.257-17.628,25.649,0,5.764,1.969,11.817,3.6,15.851l.144.385.384-.144c1.153-.529,2.4-1.009,3.843-1.538l.384-.144-.144-.384c-2.065-5.091-3.266-10.231-3.266-14.026,0-7.925,1.921-14.409,5.332-17.772a9.916,9.916,0,0,1,7.4-2.93C69.254,31.021,83.76,38.178,90,51.147Z" fill="${color}"/>
    <path d="M79.821,98.748l-.192-.192-.24.1a23.713,23.713,0,0,1-8.262,1.393A39.373,39.373,0,0,1,44.421,89.093c-3.314-3.314-5.236-6.869-5.236-9.7A10.7,10.7,0,0,1,43.7,70.264c-.576-1.393-1.248-3.026-1.825-4.755-5.091,3.41-7.589,7.973-7.589,13.881,0,4.179,2.354,8.887,6.677,13.209a44.026,44.026,0,0,0,30.212,12.393,29.234,29.234,0,0,0,11.72-2.4l.529-.24-.385-.384C81.887,100.861,80.83,99.756,79.821,98.748Z" fill="${color}"/>
    <path d="M212.872,61.138a35.579,35.579,0,0,0-13.353,2.5V89.573h8.694v-20.8a10.907,10.907,0,0,1,4.467-.864c4.707,0,5.62,2.882,5.62,8.165v13.5h8.694V74.779C226.946,66.47,224.208,61.138,212.872,61.138Z" fill="${color}"/>
    <rect x="129.343" y="51.483" width="8.694" height="38.09" fill="${color}"/>
    <polygon points="148.028 89.573 158.307 89.573 147.5 74.251 157.394 62.147 147.5 62.147 138.037 74.587 148.028 89.573" fill="${color}"/>
    <path d="M176.8,67.766c3.794,0,6.292,2.354,6.292,7.83,0,5.283-1.969,8.165-6.677,8.165a12.678,12.678,0,0,1-3.89-.624V68.583A10.773,10.773,0,0,1,176.8,67.766Zm-12.969-4.13v36.6h8.694V89.814a26.206,26.206,0,0,0,4.947.432c9.559,0,14.6-5.476,14.6-14.65,0-9.222-4.947-14.41-15.178-14.41A34.789,34.789,0,0,0,163.831,63.636Z" fill="${color}"/>
    <path d="M74.2,64.452V60.129a13.227,13.227,0,0,0-3.506-.432c-5.044,0-7.493,3.41-7.493,6.821,0,3.266,1.2,6.388,5.716,13.4a52.841,52.841,0,0,1,5.475-.433c-3.938-7.3-4.9-9.894-4.9-11.768,0-2.161,1.249-3.266,3.8-3.266Z" fill="${color}"/>
    <path d="M60.512,70.793a17.462,17.462,0,0,1-.769-3.7c-3.986-.144-7.012,2.161-7.012,5.38,0,2.593,1.345,4.9,6.82,9.558A44.7,44.7,0,0,1,64.4,80.687c-5.38-5.043-6.245-6.388-6.245-7.877C58.254,71.753,59.023,71.033,60.512,70.793Z" fill="${color}"/>
    <path d="M77.9,64.452V60.129a13.232,13.232,0,0,1,3.506-.432c5,0,7.493,3.41,7.493,6.821,0,3.266-1.2,6.388-5.716,13.4a52.841,52.841,0,0,0-5.475-.433c3.938-7.3,4.9-9.894,4.9-11.768,0-2.161-1.249-3.266-3.794-3.266Z" fill="${color}"/>
    <path d="M91.589,70.793a17.338,17.338,0,0,0,.769-3.7c3.987-.144,7.013,2.161,7.013,5.38,0,2.593-1.345,4.9-6.821,9.558A44.684,44.684,0,0,0,87.7,80.687c5.379-5.043,6.244-6.388,6.244-7.877C93.847,71.753,93.078,71.033,91.589,70.793Z" fill="${color}"/>
    <path d="M76.075,88.613a42.421,42.421,0,0,1,12.44,1.825l1.441-4.8a49.084,49.084,0,0,0-27.859,0l1.441,4.8A43.51,43.51,0,0,1,76.075,88.613Z" fill="${color}"/>
    <rect x="72.915" y="49.758" width="6.293" height="6.293" transform="translate(-15.133 69.272) rotate(-44.996)" fill="${color}"/>
</svg>`;

const KPNLogo = ({ width, color }: KPNLogoProps) => {
    if (!color) {
        color = colors.white;
    }
    const height = Math.round(width / 2);
    return (
        <Text></Text>
        // <SvgXml
        //     xml={logoXmlTemplate(color)}
        //     width={width}
        //     height={height}></SvgXml>
    );
};

export default KPNLogo;
