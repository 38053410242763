import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View, Keyboard } from 'react-native';
import { useAppDispatch } from '../../hooks';
import ChannelsGrouppingService, {
    ChannelInforWithNeighbours,
} from '../../services/channels-groupping.service';
import ChannelsSwitchingService from '../../services/channels-switching.service';
import { hideBottomOverlay, switchChannel } from '../../state/actions';
import {
    ChannelDetails,
    ChannelInfo,
} from '../../utils/zaphod/content.interface';
import ChannelSelectorItem from './ChannelSelectorItem';
import FastForwardButton from './FastForwardButton';
import RewindButton from './RewindButton';

interface ChannelsSelectorProps {
    items: ChannelInfo[];
    currentlyWatchedChannel: ChannelDetails;
}

const ChannelsSelector = ({
    items,
    currentlyWatchedChannel,
}: ChannelsSelectorProps) => {
    const dispatch = useAppDispatch();

    const [currentChannels, setCurrentChannels] = useState<
        ChannelInforWithNeighbours[]
    >(
        ChannelsGrouppingService.pickNeighboursForAChannel(
            items,
            currentlyWatchedChannel.sources.id,
        ),
    );

    useEffect(() => {
        const listener = window.addEventListener('keydown', e => {
            if (e.key === 'ArrowRight') {
                console.log(' -> ');
            }
            // console.log('Key pressed', e.key);
        });
        console.log(listener);
    }, []);

    useEffect(() => {
        setCurrentChannels(
            ChannelsGrouppingService.pickNeighboursForAChannel(
                items,
                currentlyWatchedChannel.sources.id,
            ),
        );
    }, [currentlyWatchedChannel]);

    const scrollLeft = (step: number = 1) => {
        let channleIndexToSwitchTo = currentChannels.length - 1;
        for (
            let index = 0;
            index < currentChannels.length;
            index = index + step
        ) {
            const channel = currentChannels[index];
            if (channel.isCentral) {
                setCurrentChannels(
                    ChannelsGrouppingService.pickNeighboursForAChannel(
                        items,
                        currentChannels[channleIndexToSwitchTo].channel.id,
                    ),
                );
                return;
            }
            channleIndexToSwitchTo = index;
        }
    };

    const scrollRight = (step: number = 1) => {
        let channleIndexToSwitchTo = 0;
        for (
            let index = currentChannels.length - 1;
            index > 0;
            index = index - step
        ) {
            const channel = currentChannels[index];
            if (!switchChannel) {
                ChannelsGrouppingService.pickNeighboursForAChannel(
                    items,
                    currentChannels[channleIndexToSwitchTo].channel.id,
                );
                setCurrentChannels(
                    ChannelsGrouppingService.pickNeighboursForAChannel(
                        items,
                        currentChannels[channleIndexToSwitchTo].channel.id,
                    ),
                );
                return;
            }
            if (channel.isCentral) {
                setCurrentChannels(
                    ChannelsGrouppingService.pickNeighboursForAChannel(
                        items,
                        currentChannels[channleIndexToSwitchTo].channel.id,
                    ),
                );
                return;
            }
            channleIndexToSwitchTo = index;
        }
    };

    const channelSwitch = (channelId: string) => {
        dispatch(hideBottomOverlay());
        ChannelsSwitchingService.getInstance().switchTo(
            channelId,
            (response: any) => {
                dispatch(switchChannel(response));
            },
        );
    };

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <RewindButton
                    onPress={() => {
                        scrollLeft();
                    }}
                />

                {currentChannels.map((channel, index) => {
                    if (index === 2) {
                        return (
                            <Pressable
                                style={styles.pressable}
                                onPress={() => {
                                    if (
                                        currentlyWatchedChannel.sources.id !==
                                        channel.channel.id
                                    ) {
                                        channelSwitch(channel.channel.id);
                                    }
                                }}
                                key={channel.channel.id}>
                                <ChannelSelectorItem
                                    channel={channel}
                                    index={index}></ChannelSelectorItem>
                            </Pressable>
                        );
                    }
                    return (
                        <Pressable
                            style={styles.pressable}
                            onPress={() => {
                                if (index === 0) {
                                    scrollLeft(2);
                                }
                                if (index === 1) {
                                    scrollLeft();
                                }
                                if (index === 3) {
                                    scrollRight();
                                }
                                if (index === 4) {
                                    scrollRight(2);
                                }
                                channelSwitch(channel.channel.id);
                            }}
                            key={channel.channel.id}>
                            <ChannelSelectorItem
                                channel={channel}
                                index={index}></ChannelSelectorItem>
                        </Pressable>
                    );
                })}
                <FastForwardButton
                    onPress={() => {
                        scrollRight();
                    }}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignContent: 'center',
    },
    containerItem: {
        width: 150,
        textAlignVertical: 'center',
        textAlign: 'center',
        overflow: 'visible',
    },
    pressable: {
        cursor: 'pointer',
    },
});

export default ChannelsSelector;
