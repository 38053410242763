import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { STREAM_TYPE_TO_REQUEST } from '../../../App';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ChannelsSwitchingService from '../../services/channels-switching.service';
import DeviceIdService from '../../services/device-id';
import EPGService from '../../services/epg';
import { loadChannels, switchChannel } from '../../state/actions';
import { setDeviceId } from '../../state/store/auth';
import {
    IpForbiddenError,
    ZaphodClient,
} from '../../utils/zaphod/zaphod-client';
import BlockedIpScreen from '../LoginScreen/BlockedIpScreen';
import WelcomeScreen from '../WelcomeScreen/WelcomeScreen';

const MainAppScreen = () => {
    const dispatch = useAppDispatch();
    const authState = useAppSelector(state => state.auth);
    const availableChannels = useAppSelector(
        state => state.stream.availableChannels,
    );
    const [channelIsPreloaded, setChannelIsPreloaded] =
        useState<boolean>(false);
    const client = ZaphodClient.instance();
    DeviceIdService.getDeviceId().then(id => dispatch(setDeviceId(id)));
    const [ipForbidden, setIpForbidden] = useState<boolean>(false);

    const preloadChannels = async () => {
        try {
            const channels = await client.channels();
            dispatch(loadChannels(channels));
        } catch (error) {
            if (error instanceof IpForbiddenError) {
                setIpForbidden(true);
            }
            console.log('Error fetching channels', error.message);
        }
    };

    useEffect(() => {
        if (availableChannels.length > 0 && !channelIsPreloaded) {
            const id = availableChannels[0].id;
            Promise.all([
                client
                    .channelDetails(
                        id,
                        authState.deviceId,
                        STREAM_TYPE_TO_REQUEST,
                    )
                    .then(channel => dispatch(switchChannel(channel)))
                    .catch(error =>
                        console.log('Channel details error', error),
                    ),
                client
                    .epg()
                    .then(response => {
                        EPGService.createWithItems(response);
                    })
                    .catch(error => console.log('EPG load failed')),
            ]).then(() => setChannelIsPreloaded(true));
        }
    }, [availableChannels]);

    useEffect(() => {
        if (authState.deviceId) {
            ChannelsSwitchingService.initWithClientAndDeviceId(
                ZaphodClient.instance(),
                authState.deviceId,
            );
            preloadChannels();
        }
    }, [authState.deviceId]);

    const onRegistrationSuccess = () => {
        setIpForbidden(false);
        preloadChannels();
    };

    return (
        <View
            style={{
                flex: 1,
            }}>
            {ipForbidden && (
                <BlockedIpScreen
                    onSuccess={onRegistrationSuccess}></BlockedIpScreen>
            )}
            {!ipForbidden && channelIsPreloaded && (
                <WelcomeScreen></WelcomeScreen>
            )}
            {!ipForbidden && !channelIsPreloaded && <Text>Loading ...</Text>}
        </View>
    );
};

export default MainAppScreen;
