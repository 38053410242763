import React, { useState } from 'react';
import { Keyboard, StyleSheet, Text, View } from 'react-native';
import { colors } from '../theming/colors';
import defaultStyles from '../theming/dark';
import { ZaphodClient } from '../utils/zaphod/zaphod-client';
import CustomButton from './CustomButton';
import InputControllableByRemote from './InputControllableByRemote';
import KPNLogo from './KPNLogo';

interface LoginScreenProps {
    loginHeader?: string;
    buttonText?: string;
    submittingMessage?: string;
    onSuccess?: CallableFunction;
    onError?: CallableFunction;
}

export const REASON_IP_NOT_ALLOWED = 'REASON_IP_NOT_ALLOWED';

const BasicLoginComponent = ({
    loginHeader,
    onSuccess,
    onError,
    submittingMessage,
    buttonText,
}: LoginScreenProps) => {
    const client = ZaphodClient.instance();
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');

    let loginHeaderComponents: any = '';

    if (loginHeader) {
        loginHeaderComponents = loginHeader.split('\\n').map((item, index) => (
            <Text key={`lognHeader-${index}`} style={styles.screenText}>
                {item}
            </Text>
        ));
    }

    const inputStyles = {
        onBlur: StyleSheet.flatten([
            defaultStyles.inputSizing,
            defaultStyles.defaultInput,
        ]),
        onFocus: StyleSheet.flatten([
            defaultStyles.inputSizing,
            defaultStyles.defaultInputFocused,
        ]),
    };

    const submitData = () => {
        setErrorText('');
        setSubmitting(true);
        setTimeout(() => {
            client
                .allowIp({ username: login, password })
                .then(response => {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                })
                .catch(error => {
                    if (
                        error &&
                        error.response &&
                        error.response.status === 404 &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        setErrorText(error.response.data.message);
                    }
                    console.log('Error', error.response.data);
                    if (onError) {
                        onError(error);
                    }
                })
                .finally(() => setSubmitting(false));
        }, 1000);
    };

    return (
        <View style={styles.screenContainer}>
            {!submitting && (
                <View style={styles.loginBadge}>
                    {/* <KPNLogo width={200} color={colors.grey}></KPNLogo> */}

                    {loginHeaderComponents}

                    <InputControllableByRemote
                        inputStyles={inputStyles}
                        containerStyles={defaultStyles.defaultInputContainer}
                        placeholder="Login"
                        value={login}
                        onChangeText={(text: any) => {
                            setLogin(text);
                        }}></InputControllableByRemote>
                    <InputControllableByRemote
                        inputStyles={inputStyles}
                        containerStyles={defaultStyles.defaultInputContainer}
                        placeholder="Password"
                        value={password}
                        secureTextEntry={true}
                        onChangeText={(text: any) => {
                            setPassword(text);
                        }}></InputControllableByRemote>
                    {errorText !== '' && (
                        <Text style={styles.errorText}>{errorText}</Text>
                    )}
                    <CustomButton
                        containerStyles={{ marginTop: 20 }}
                        onPress={() => {
                            Keyboard.dismiss();
                            submitData();
                        }}
                        text={buttonText}></CustomButton>
                </View>
            )}
            {submitting && (
                <View style={styles.loginBadge}>
                    <KPNLogo width={200} color={colors.grey}></KPNLogo>
                    <Text style={styles.screenText}>{submittingMessage}</Text>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    screenContainer: {
        backgroundColor: colors.black,
        height: '100%',
        justifyContent: 'center',
    },
    loginBadge: {
        alignItems: 'center',
    },
    screenText: {
        color: colors.brightGrey,
    },
    errorText: {
        color: colors.white,
        padding: 10,
        backgroundColor: '#FE6D73',
        marginTop: 10,
    },
});

export default BasicLoginComponent;
