import React from 'react';
import BasicLoginComponent from '../../components/BasicLoginComponent';

interface BlockedIpScreenProps {
    onSuccess: CallableFunction;
}

const BlockedIpScreen = ({ onSuccess }: BlockedIpScreenProps) => {
    return (
        <BasicLoginComponent
            onSuccess={onSuccess}
            submittingMessage="Registering ..."
            loginHeader="This device is not registered. \n You can register this device with your credentials"
            buttonText="Register"></BasicLoginComponent>
    );
};

export default BlockedIpScreen;
