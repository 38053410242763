export interface ChannelDetails {
    info: string;
    sources: {
        id: string;
        label: string;
        src: string;
        deviceId: string;
        type: string;
        contentProtection: any;
    };
}

export interface ChannelInfo {
    id: string;
    fullname: string;
    label: string;
    notes: string;
    createdAt: string;
    updatedAt: string;
}

export class ChannelDetailsFactory {
    public static unknownChannel(): ChannelDetails {
        return {
            info: 'unknow',
            sources: {
                id: 'unknown',
                label: 'unknown',
                src: 'unknown',
                deviceId: 'unknown',
                type: 'unknown',
                contentProtection: null,
            },
        };
    }
}
