import React, { FC } from 'react';
import { Pressable, Image } from 'react-native';

interface IProps {
    onPress: CallableFunction;
}
const DownButton: FC<IProps> = ({ onPress }) => {
    return (
        <Pressable onPress={onPress}>
            <Image
                style={{
                    width: 50,
                    height: 50,
                    resizeMode: 'contain',
                }}
                source={{
                    uri: "data:image/svg+xml,%3Csvg id='SvgjsSvg1001' width='288' height='288' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs'%3E%3Cdefs id='SvgjsDefs1002'%3E%3C/defs%3E%3Cg id='SvgjsG1008' transform='matrix(1,0,0,1,0,0)'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='288' enable-background='new 0 0 512 512' viewBox='0 0 512 512'%3E%3Cpolygon points='396.6 160 416 180.7 256 352 96 180.7 115.3 160 256 310.5' fill='%23ffffff' class='color000 svgShape'%3E%3C/polygon%3E%3C/svg%3E%3C/g%3E%3C/svg%3E",
                }}
            />
        </Pressable>
    );
};

export default DownButton;
