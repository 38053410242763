const rgb2styles = (rgb: any[]) => {
    return `rgb(${rgb.join(',')})`;
};

interface RgbsCollection {
    [rgbName: string]: [number, number, number];
}

const rgbs: RgbsCollection = {
    white: [255, 255, 255],
    brightGrey: [160, 160, 160],
    grey: [86, 89, 97],
    darkGrey: [67, 69, 76],
    black: [30, 30, 30],

    kpnGreen: [1, 195, 2],
};

const colors = {
    white: rgb2styles(rgbs.white),
    brightGrey: rgb2styles(rgbs.brightGrey),
    grey: rgb2styles(rgbs.grey),
    darkGrey: rgb2styles(rgbs.darkGrey),
    black: rgb2styles(rgbs.black),
    kpnGreen: rgb2styles(rgbs.kpnGreen),
};

const transparentColor = (colorToGet: string, transparency: number) => {
    return `rgba(${rgbs[colorToGet].join(',')},${transparency})`;
};

export { colors, transparentColor };
