import React from 'react';

// import "react-native/tvos-types.d";
import { Provider } from 'react-redux';
import MainAppScreen from './app/screens/MainAppScreen/MainAppScreen';
import store from './app/state/store';
import * as device from 'react-device-detect';

export const STREAM_TYPE_TO_REQUEST = device.isSafari ? 'hls' : 'dash';

const App = () => {
    return (
        <Provider store={store}>
            <MainAppScreen></MainAppScreen>
        </Provider>
    );
};

export default App;
