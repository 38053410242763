import { BACKEND_BASE_URL } from '../../config/const';
import { EPGItem } from '../../services/epg';
import { ZaphodCredentials } from './auth';
import { ChannelDetails, ChannelInfo } from './content.interface';
import { ChannelDetailsResponseAdapter } from './http-adapters/channel-details';
import { ChannelsReponseAdapter } from './http-adapters/channels';
import EPGResponseAdapter from './http-adapters/epg';

export class Non200Response extends Error {
    constructor(public readonly response: any) {
        super('WebClient: non-200 response');
    }
}

export class IpForbiddenError extends Error {
    constructor() {
        super('IP is forbidden');
    }
}

export class ZaphodClient {
    private static client: ZaphodClient;
    private readonly apiUrl: string;

    private constructor(private readonly host: string) {
        this.apiUrl = `${host}/api/v1`;
    }

    private async sendRequest(uri: string, options: RequestInit) {
        console.log(`-> ${uri}`, options.body);
        const response = await fetch(`${this.apiUrl}${uri}`, options);
        console.log(`<- [${response.status}] ${uri}`, options.body);
        if (response.status !== 200) {
            if (response.status === 403) {
                const json = await response.json();
                if (json.error && json.error === 'IP is not allowed') {
                    throw new IpForbiddenError();
                }
            }
            throw new Non200Response(response);
        }
        return response;
    }

    private async post(uri: string, body: any = {}, headers: any = {}) {
        const bodyString = JSON.stringify(body);
        return this.sendRequest(uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: bodyString !== '{}' ? bodyString : null,
        });
    }

    public static instance(host: string = BACKEND_BASE_URL) {
        if (ZaphodClient.client) {
            return ZaphodClient.client;
        }
        ZaphodClient.client = new ZaphodClient(host);
        return ZaphodClient.client;
    }

    public async allowIp(credentials: ZaphodCredentials) {
        return await this.post('/ip/allow', {
            username: credentials.username,
            password: credentials.password,
        });
    }

    public async channels(): Promise<ChannelInfo[]> {
        console.log('Loading channels ...');
        const response = await this.post('/content/channels');
        return ChannelsReponseAdapter.adapt(await response.json());
    }

    public async channelDetails(
        channelId: string,
        deviceId: string,
        type: string,
    ): Promise<ChannelDetails> {
        const response = await this.post('/content/channel', {
            channel_id: channelId,
            device_id: deviceId,
            type: type,
        });
        return ChannelDetailsResponseAdapter.adapt(await response.json());
    }

    public async epg(): Promise<EPGItem[]> {
        const response = await this.post('/content/epg');
        return EPGResponseAdapter.adapt(await response.json());
    }
}
