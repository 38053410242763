import React, { FC } from 'react';
import { Pressable, Image } from 'react-native';

interface IProps {
    onPress: CallableFunction;
}
const FastForwardButton: FC<IProps> = ({ onPress }) => {
    return (
        <Pressable onPress={onPress}>
            <Image
                style={{
                    width: 100,
                    height: 50,
                    resizeMode: 'contain',
                }}
                source={{
                    uri: "data:image/svg+xml,%3Csvg id='SvgjsSvg1001' width='288' height='288' xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs'%3E%3Cdefs id='SvgjsDefs1002'%3E%3C/defs%3E%3Cg id='SvgjsG1008' transform='matrix(1,0,0,1,0,0)'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 512 512' width='288' height='288'%3E%3Cpath d='M214.78,478l-20.67-21.57L403.27,256,194.11,55.57,214.78,34,446.46,256ZM317.89,256,86.22,34,65.54,55.57,274.7,256,65.54,456.43,86.22,478Z' fill='%2343454c' class='color000 svgShape'%3E%3C/path%3E%3C/svg%3E%3C/g%3E%3C/svg%3E",
                }}
            />
        </Pressable>
    );
};

export default FastForwardButton;
