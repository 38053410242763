import { EPGDataItem, EPGItem } from './epg';

export interface EPGTimeSlot {
    payload: {
        id: string;
        title: string;
        startTime: number;
        endTime: number;
    };
    width: number;
    isPlaceholder: boolean;
}

export interface EPGTimeline {
    channelId: string;
    timeslots: EPGTimeSlot[];
}

export interface EPGLayoutSummary {
    actualLayoutWidth: number;
    layoutLeftsideOffset: number;
    currentTimeMarkerOffset: number;
}

export interface EPGLayout {
    summary: EPGLayoutSummary;
    timelines: EPGTimeline[];
}

export interface EPGTimeSlotDTO {
    channelId: string;
    id: string;
    title: string;
    startTime: number;
    endTime: number;
}

export interface EPGTimeSlotDTOMap {
    [channelId: string]: EPGTimeSlotDTO[];
}

export default class EPGLayoutBuilder {
    /**
     * Number of pixels in one second of timeline
     */
    private readonly pixelsPerSecond: number;

    constructor(
        private readonly containerWidth: number,
        private readonly timelineStartTime: number,
        private readonly timelineEndTime: number,
    ) {
        const totalTimeInSeconds =
            (this.timelineEndTime - this.timelineStartTime) / 1000;
        // precision up to 0.001
        this.pixelsPerSecond =
            Math.round((this.containerWidth / totalTimeInSeconds) * 1000) /
            1000;
    }

    public forChannelsAndTimeframe(
        epgs: EPGItem[],
        channelsIds: string[],
        currentTime: number,
    ): EPGLayout {
        const timelines = this.buildTimelines(
            this.filterOutDataItemsWithinGivenTime(epgs, channelsIds),
        );

        if (timelines.length === 0) {
            return {
                summary: {
                    actualLayoutWidth: 0,
                    currentTimeMarkerOffset: 0,
                    layoutLeftsideOffset: 0,
                },
                timelines,
            };
        }

        const timelineToEvaluate = timelines[0];
        const layout: EPGLayout = {
            summary: this.calculateLayoutSummary(
                timelineToEvaluate,
                currentTime,
            ),
            timelines: timelines.sort((item1, item2) => {
                if (
                    channelsIds.indexOf(item1.channelId) >
                    channelsIds.indexOf(item2.channelId)
                ) {
                    return 1;
                }
                if (
                    channelsIds.indexOf(item1.channelId) <
                    channelsIds.indexOf(item2.channelId)
                ) {
                    return -1;
                }
                return 0;
            }),
        };

        // Sort items according to initial order of channelsIds
        return layout;
    }

    private filterOutDataItemsWithinGivenTime(
        epgs: EPGItem[],
        channelsIds: string[],
    ): EPGTimeSlotDTOMap {
        const result: EPGTimeSlotDTOMap = {};
        epgs.forEach(item => {
            const channelId = item.id;
            if (channelsIds.indexOf(channelId) === -1) {
                return;
            }
            result[channelId] = [];
            item.data.forEach(dataItem => {
                if (
                    dataItem.endTime >= this.timelineStartTime &&
                    dataItem.startTime < this.timelineEndTime
                ) {
                    result[channelId].push(
                        this.EPGDataItemToTimeSlotDTO(dataItem, channelId),
                    );
                }
            });
        });
        return result;
    }

    private buildTimelines(timeslots: EPGTimeSlotDTOMap): EPGTimeline[] {
        const timelines: EPGTimeline[] = [];
        let minTime = 2542665600000; // Friday, 29 July 2050 00:00:00
        let maxTime = 0;
        for (let channelId in timeslots) {
            const positionedTimeslots = this.positionTimelineSlots(
                timeslots[channelId],
            );
            if (positionedTimeslots.length === 0) {
                return [];
            }
            const firstSlot = positionedTimeslots[0];
            const lastSlot =
                positionedTimeslots[positionedTimeslots.length - 1];

            if (firstSlot.payload.startTime < minTime) {
                minTime = firstSlot.payload.startTime;
            }
            if (lastSlot.payload.endTime > maxTime) {
                maxTime = lastSlot.payload.endTime;
            }

            timelines.push({
                channelId,
                timeslots: positionedTimeslots,
            });
        }

        // Add placeholders for missing data
        timelines.forEach(item => {
            const firstSlot = item.timeslots[0];
            const lastSlot = item.timeslots[item.timeslots.length - 1];
            if (firstSlot.payload.startTime > minTime) {
                item.timeslots = [
                    this.placeholderSlot(minTime, firstSlot.payload.startTime),
                    ...item.timeslots,
                ];
            }
            if (lastSlot.payload.endTime < maxTime) {
                item.timeslots = [
                    ...item.timeslots,
                    this.placeholderSlot(lastSlot.payload.endTime, maxTime),
                ];
            }
        });

        return timelines;
    }

    private positionTimelineSlots(timeslots: EPGTimeSlotDTO[]): EPGTimeSlot[] {
        // sort by startTime
        timeslots.sort((item1, item2) => {
            if (item1.startTime < item2.startTime) {
                return -1;
            }
            if (item1.startTime > item2.startTime) {
                return 1;
            }
            return 0;
        });

        return timeslots.map(slot => this.TimeSlotDTOToTimeSlot(slot));
    }

    private EPGDataItemToTimeSlotDTO(
        dataItem: EPGDataItem,
        channelId: string,
    ): EPGTimeSlotDTO {
        return {
            channelId,
            id: dataItem.id,
            title: dataItem.title,
            startTime: dataItem.startTime,
            endTime: dataItem.endTime,
        };
    }

    private TimeSlotDTOToTimeSlot(slot: EPGTimeSlotDTO): EPGTimeSlot {
        return {
            isPlaceholder: false,
            width: Math.floor(
                Math.floor((slot.endTime - slot.startTime) / 1000) *
                    this.pixelsPerSecond,
            ),
            payload: {
                id: slot.id,
                title: slot.title,
                startTime: slot.startTime,
                endTime: slot.endTime,
            },
        };
    }
    private placeholderSlot(startTime: number, endTime: number): EPGTimeSlot {
        return {
            isPlaceholder: true,
            width: Math.floor(
                Math.floor((endTime - startTime) / 1000) * this.pixelsPerSecond,
            ),
            payload: {
                id: `placeholrder-${startTime}`,
                title: '',
                startTime: startTime,
                endTime: endTime,
            },
        };
    }

    private calculateLayoutSummary(
        timelineToEvaluate: EPGTimeline,
        currentTime: number,
    ): EPGLayoutSummary {
        const actualLayoutStatTime =
            timelineToEvaluate.timeslots[0].payload.startTime;
        const actualLayoutEndTime =
            timelineToEvaluate.timeslots[
                timelineToEvaluate.timeslots.length - 1
            ].payload.endTime;

        const layoutLeftsideOffset = Math.floor(
            ((this.timelineStartTime - actualLayoutStatTime) / 1000) *
                this.pixelsPerSecond,
        );
        return {
            actualLayoutWidth: Math.floor(
                ((actualLayoutEndTime - actualLayoutStatTime) / 1000) *
                    this.pixelsPerSecond,
            ),
            layoutLeftsideOffset,
            currentTimeMarkerOffset: Math.floor(
                ((currentTime - this.timelineStartTime) / 1000) *
                    this.pixelsPerSecond,
            ),
        };
    }

    public static emptyLayout(): EPGLayout {
        return {
            summary: {
                actualLayoutWidth: 0,
                currentTimeMarkerOffset: 0,
                layoutLeftsideOffset: 0,
            },
            timelines: [],
        };
    }
}
