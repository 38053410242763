import { ChannelInfo } from '../utils/zaphod/content.interface';
import { EPGItem } from './epg';

export interface ChannelInforWithNeighbours {
    channel: ChannelInfo | EPGItem;
    isCentral: boolean;
}

export default class ChannelsGrouppingService {
    public static pickNeighboursForAChannel(
        channels: ChannelInfo[] | EPGItem[],
        id: string,
        totalNeightboursEachSide: number = 2,
    ): ChannelInforWithNeighbours[] {
        const index = channels.findIndex(
            item => ChannelsGrouppingService.getChannelId(item) === id,
        );
        if (index === -1) {
            return [];
        }

        return ChannelsGrouppingService.sliceChannelsWithIds(
            channels,
            ChannelsGrouppingService.getNeighboursForIds(
                channels.map(item =>
                    ChannelsGrouppingService.getChannelId(item),
                ),
                id,
                totalNeightboursEachSide,
            ),
            false,
        ).map(item => {
            if (ChannelsGrouppingService.getChannelId(item.channel) === id) {
                item.isCentral = true;
            }
            return item;
        });
    }

    public static getNeighboursForIds(
        ids: string[],
        id: string,
        totalNeightboursEachSide: number = 2,
    ): string[] {
        const index = ids.indexOf(id);
        if (index === -1) {
            return [];
        }

        return [
            ...ChannelsGrouppingService.pickLeftSideNeighbours(
                index,
                ids,
                totalNeightboursEachSide,
            ),

            id,

            ...ChannelsGrouppingService.pickRightSideNeighbours(
                index,
                ids,
                totalNeightboursEachSide,
            ),
        ];
    }

    private static pickLeftSideNeighbours(
        index: number,
        ids: string[],
        totalNeightboursEachSide: number,
    ): string[] {
        // Not enough left-side neighbours: pick items from the end of array and put them to the beginning of result
        if (index - totalNeightboursEachSide < 0) {
            return [
                ...ids.slice(index - totalNeightboursEachSide), // items from the end of array
                ...ids.slice(0, index),
            ];
        }
        return ids.slice(index - totalNeightboursEachSide, index);
    }

    private static pickRightSideNeighbours(
        index: number,
        ids: string[],
        totalNeightboursEachSide: number,
    ): string[] {
        const firstNeighbourIndex = index + 1;
        if (firstNeighbourIndex + totalNeightboursEachSide > ids.length) {
            const missingNeighboursNumber =
                firstNeighbourIndex + totalNeightboursEachSide - ids.length;
            // Not enough right-side neighbours: pick items from the beginning of array and put them to the end of result
            return [
                ...ids.slice(firstNeighbourIndex),
                ...ids.slice(0, missingNeighboursNumber), // items from the beginning of array
            ];
        }
        return ids.slice(
            firstNeighbourIndex,
            firstNeighbourIndex + totalNeightboursEachSide,
        );
    }

    private static getChannelId(channel: any): string {
        if (channel.id) {
            return channel.id;
        }
        if (channel.sources && channel.sources.id) {
            return channel.sources.id;
        }
        return '';
    }

    private static wrap(
        channel: ChannelInfo | EPGItem,
        isCentral: boolean,
    ): ChannelInforWithNeighbours {
        return {
            channel,
            isCentral,
        };
    }

    private static sliceChannelsWithIds(
        channels: EPGItem[] | ChannelInfo[],
        ids: string[],
        isCentral: boolean,
    ): ChannelInforWithNeighbours[] {
        const channelsMap: any = {};
        for (let index in channels) {
            channelsMap[
                ChannelsGrouppingService.getChannelId(channels[index])
            ] = channels[index];
        }
        return ids
            .map(id => channelsMap[id])
            .map(channel => ChannelsGrouppingService.wrap(channel, isCentral));
    }
}
