import { ChannelInfo } from '../content.interface';

export class ChannelsReponseAdapter {
    public static adapt(response: any): ChannelInfo[] {
        if (!response || !response.channels || response.channels.length === 0) {
            throw new Error('Invalid channels reponse format');
        }

        const result: ChannelInfo[] = [];

        for (const channel of response.channels) {
            result.push({
                id: channel.id,
                createdAt: channel.created_at,
                updatedAt: channel.updated_at,
                fullname: channel.fullname,
                label: channel.label,
                notes: channel.notes,
            });
        }

        return result;
    }
}
