import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DebugMessage {
    message: string;
    timestamp?: number;
    data?: any;
}

interface DebugConsoleState {
    general: DebugMessage[];
    player: DebugMessage[];
}

const initialState: DebugConsoleState = {
    general: [],
    player: [],
};

const MAX_MESSAGES_ALLOWED = 10;
const arrayWithANewElementAndMaxAllowedLength = (
    initialArray: any[],
    newElement: DebugMessage,
    maxLength: number,
) => {
    const arrayOfMaxAllowedLength = initialArray.slice(-maxLength);
    arrayOfMaxAllowedLength.push(newElement);
    return arrayOfMaxAllowedLength;
};

const debugConsoleSlice = createSlice({
    name: 'debug',
    initialState,
    reducers: {
        player: (state, action: PayloadAction<DebugMessage>) => {
            state.player = arrayWithANewElementAndMaxAllowedLength(
                state.player,
                action.payload,
                MAX_MESSAGES_ALLOWED,
            );
        },
        general: (state, action: PayloadAction<DebugMessage>) => {
            state.general = arrayWithANewElementAndMaxAllowedLength(
                state.general,
                action.payload,
                MAX_MESSAGES_ALLOWED,
            );
        },
    },
});

const { general, player } = debugConsoleSlice.actions;

const logPlayer = (message: string, data?: any) => {
    return player({
        message,
        timestamp: Date.now(),
        data: data,
    });
};

const log = (message: string, data?: any) => {
    return general({
        message,
        timestamp: Date.now(),
        data: data,
    });
};

export { log, logPlayer };

export default debugConsoleSlice.reducer;
