import { Image } from 'react-native';

export interface EPGDataItem {
    id: string;
    title: string;
    description: string;
    image: string;
    duration: number;
    startTime: number;
    endTime: number;
    season: number;
    link: string;
    episodeId: string;
    pclevelepg: string;
}

export interface EPGItem {
    id: string;
    image: string;
    name: string;
    total: number;
    data: EPGDataItem[];
}

export interface ImageMeta {
    width: number;
    height: number;
    src: string;
}

interface ImageMetaCollection {
    [channelId: string]: ImageMeta;
}

export interface EPGItemsCollection {
    [key: string]: EPGItem;
}

export default class EPGService {
    public static readonly DEFAULT_IMAGE_WIDTH: number = 100;
    public static readonly DEFAULT_IMAGE_HEIGHT: number = 100;
    private readonly itemsCollection: EPGItemsCollection = {};
    private static instance: EPGService;
    private imagesMetaCache: ImageMetaCollection = {};

    private constructor(items: EPGItem[], fetchImageSizes: boolean) {
        for (const item of items) {
            if (fetchImageSizes) {
                this.imagesMetaCache[item.id] = {
                    height: 0,
                    width: 0,
                    src: item.image,
                };
                Image.getSize(item.image, (width: number, height: number) => {
                    this.imagesMetaCache[item.id] = {
                        width,
                        height,
                        src: item.image,
                    };
                });
            } else {
                this.imagesMetaCache[item.id] = {
                    width: EPGService.DEFAULT_IMAGE_WIDTH,
                    height: EPGService.DEFAULT_IMAGE_HEIGHT,
                    src: item.image,
                };
            }
            this.itemsCollection[item.id] = item;
        }
    }

    public static getInstance(): EPGService {
        if (!this.instance) {
            return EPGService.createWithItems([], false);
        }
        return this.instance;
    }

    public getEpgsForChannels(channelIds: string[]): EPGItem[] {
        return channelIds
            .filter(
                channelId =>
                    this.itemsCollection[channelId] &&
                    this.itemsCollection[channelId].id !== '',
            )
            .map(channelId => this.itemsCollection[channelId]);
    }

    public static createWithItems(
        items: EPGItem[],
        fetchImageSizes: boolean = true,
    ): EPGService {
        if (
            !EPGService.instance ||
            EPGService.instance.itemsCollection === {}
        ) {
            EPGService.instance = new EPGService(items, fetchImageSizes);
        }
        return EPGService.instance;
    }

    public imageForChannel(channelId: string): ImageMeta {
        if (!this.imagesMetaCache[channelId]) {
            return { width: 0, height: 0, src: '' };
        }
        return {
            width: this.imagesMetaCache[channelId].width,
            height: this.imagesMetaCache[channelId].height,
            src: this.imagesMetaCache[channelId].src,
        };
    }

    public allChannelsDataForTimestamp(
        timestamp: number,
        futureTimeLimitInMilliseconds: number = 3600 * 1000 * 3, // Default: 3 hours in future
    ): EPGItemsCollection {
        const result: EPGItemsCollection = {};
        for (let channelId in this.itemsCollection) {
            if (
                this.itemsCollection[channelId].data &&
                this.itemsCollection[channelId].data.length > 0
            ) {
                const itemsFromFuture: EPGDataItem[] = [];
                const currentlyPlaying: EPGDataItem[] = [];
                const itemFromThePast: EPGDataItem[] = [];
                const lastIndex =
                    this.itemsCollection[channelId].data.length - 1;

                for (let i = lastIndex; i >= 0; i--) {
                    const item = this.itemsCollection[channelId].data[i];

                    if (
                        item.startTime >
                        timestamp + futureTimeLimitInMilliseconds
                    ) {
                        continue;
                    }

                    if (item.startTime > timestamp) {
                        itemsFromFuture.push(item);
                        continue;
                    }
                    if (
                        item.startTime <= timestamp &&
                        item.endTime > timestamp
                    ) {
                        currentlyPlaying.push(item);
                        if (this.itemsCollection[channelId].data[i - 1]) {
                            itemFromThePast.push(
                                this.itemsCollection[channelId].data[i - 1],
                            );
                        }
                        break;
                    }
                }

                const resultingItems = [
                    ...itemFromThePast,
                    ...currentlyPlaying,
                    ...itemsFromFuture.reverse(),
                ];
                result[channelId] = {
                    id: this.itemsCollection[channelId].id,
                    image: this.itemsCollection[channelId].image,
                    name: this.itemsCollection[channelId].name,
                    total: resultingItems.length,
                    data: resultingItems,
                };
            }
        }
        return result;
    }
}
