import React, { useState } from 'react';
import * as device from 'react-device-detect';
import { Dimensions, Text, View } from 'react-native';
import { useAppSelector } from '../../hooks';
import { colors, transparentColor } from '../../theming/colors';
import ChannelLoadingBadge from './ChannelLoadingBadge';
import ZaphodShakaPLayer from './ZaphodShakaPlayer';

const StreamsPlayer = () => {
    const { currentlyWatchedChannel } = useAppSelector(state => state.stream);
    const [channelIsBeingLoaded, setChannelIsBeingLoaded] =
        useState<boolean>(false);
    const drm = currentlyWatchedChannel.sources.contentProtection;
    const window = Dimensions.get('window');

    return (
        <View
            style={{
                flex: 1,
                backgroundColor: 'black',
            }}
            onLayout={() => {}}>
            {channelIsBeingLoaded && (
                <ChannelLoadingBadge
                    channel={
                        currentlyWatchedChannel.sources.label
                    }></ChannelLoadingBadge>
            )}

            {/* {muted && currentlyWatchedChannel.info !== 'unknown' && (
                <View
                    onClick={() => {
                        setMuted(!muted);
                    }}
                    style={{
                        position: 'absolute',
                        backgroundColor: transparentColor('darkGrey', 0.9),
                        padding: 20,
                        borderRadius: 5,
                        zIndex: 1000,
                        top: Math.floor(window.height / 2) - 150,
                        left: Math.floor(window.width) / 2 - 50,
                    }}>
                    <Text
                        style={{
                            color: colors.white,
                            fontSize: 30,
                            textAlign: 'center',
                        }}>
                        Video is muted
                    </Text>
                    <Text style={{ color: colors.white, textAlign: 'center' }}>
                        Click to unmute
                    </Text>
                </View>
            )} */}

            {currentlyWatchedChannel.info !== 'unknown' && !device.isSafari && (
                <View style={{ flex: 1 }}>
                    <ZaphodShakaPLayer
                        muted={true}
                        fullscreen={false}
                        drm={drm}
                        src={
                            currentlyWatchedChannel.sources.src
                        }></ZaphodShakaPLayer>
                </View>
            )}

            {currentlyWatchedChannel.info !== 'unknown' &&
                device.isSafari &&
                (!drm || !drm.fairplay) && (
                    <View style={{ flex: 1 }}>
                        <ZaphodShakaPLayer
                            muted={true}
                            fullscreen={false}
                            drm={drm}
                            src={
                                currentlyWatchedChannel.sources.src
                            }></ZaphodShakaPLayer>
                    </View>
                )}

            {currentlyWatchedChannel.info !== 'unknown' &&
                device.isSafari &&
                drm &&
                drm.fairplay && (
                    <View style={{ flex: 1 }}>
                        <View
                            style={{
                                position: 'absolute',
                                padding: 20,
                                borderRadius: 5,
                                zIndex: 1000,
                                top: Math.floor(window.height / 2) - 150,
                                left: 0,
                                right: 0,
                                alignItems: 'center',
                            }}>
                            <View
                                style={{
                                    backgroundColor: transparentColor(
                                        'darkGrey',
                                        0.9,
                                    ),
                                    padding: 20,
                                    borderRadius: 5,
                                }}>
                                <Text
                                    style={{
                                        color: colors.white,
                                        fontSize: 30,
                                        textAlign: 'center',
                                    }}>
                                    Sorry, but{' '}
                                    {currentlyWatchedChannel.sources.label} is
                                    not supported on Safari
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
        </View>
    );
};

export default StreamsPlayer;
