import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { ChannelInforWithNeighbours } from '../../services/channels-groupping.service';
import EPGService from '../../services/epg';
import { colors } from '../../theming/colors';

interface ChannelSelectorItemProps {
    channel: ChannelInforWithNeighbours;
    index: number;
}

const MID_ITEM_HEIGHT = 60;
const NEXT_TO_CENTER_ITEM_HEIGHT = 50;
const SIDE_ITEM_HEIGHT = 40;
const MID_ITEM_WIDTH = 60;
const NEXT_TO_CENTER_ITEM_WIDTH = 50;
const SIDE_ITEM_WIDTH = 40;

const determineSizesBasedOnPoisition = (index: number) => {
    if (index === 2) {
        // center item
        return {
            width: MID_ITEM_WIDTH,
            height: MID_ITEM_HEIGHT,
            marginLeft: 10,
            marginRight: 10,
        };
    }
    if (index === 1 || index === 3) {
        // items next to center
        return {
            width: NEXT_TO_CENTER_ITEM_WIDTH,
            height: NEXT_TO_CENTER_ITEM_HEIGHT,
            marginLeft: index === 1 ? 5 : 0,
            marginRight: index === 3 ? 5 : 0,
        };
    }
    return {
        // side items
        width: SIDE_ITEM_WIDTH,
        height: SIDE_ITEM_HEIGHT,
        marginLeft: 0,
        marginRight: 0,
    };
};

const ChannelSelectorItem = ({ channel, index }: ChannelSelectorItemProps) => {
    const image = EPGService.getInstance().imageForChannel(channel.channel.id);
    const { marginLeft, marginRight, width, height } =
        determineSizesBasedOnPoisition(index);
    return (
        <View
            key={`${channel.channel.id}:${Math.random()}`}
            style={{
                marginLeft,
                marginRight,
                ...styles.containerWithItemsColumnedAndCentered,
                height: MID_ITEM_HEIGHT,
            }}>
            <View
                style={{
                    ...styles.containerWithItemsRowedAndCentered,
                    height: height + 6,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: channel.isCentral
                        ? colors.brightGrey
                        : colors.darkGrey,
                    backgroundColor: colors.darkGrey,
                    width: 100,
                    padding: 2,
                    alignItems: 'center',
                }}>
                <Image
                    source={{ uri: image.src }}
                    style={{
                        height,
                        width,
                        resizeMode: 'contain',
                    }}></Image>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    containerWithItemsRowedAndCentered: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    containerWithItemsColumnedAndCentered: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
    },
});

export default ChannelSelectorItem;
