import { ChannelDetails } from '../content.interface';

export class ChannelDetailsResponseAdapter {
    public static adapt(response: any): ChannelDetails {
        if (
            !response ||
            !response.details ||
            !response.details.info ||
            !response.details.sources
        ) {
            throw new Error('Invalid channels details reponse format');
        }
        return {
            info: response.details.info,
            sources: {
                id: response.details.sources.id,
                deviceId: response.details.sources.deviceid,
                label: response.details.sources.label,
                src: response.details.sources.src,
                type: response.details.sources.type,
                contentProtection: response.details.sources.contentProtection,
            },
        };
    }
}
