import { STREAM_TYPE_TO_REQUEST } from '../../App';
import { ChannelInfo } from '../utils/zaphod/content.interface';
import { ZaphodClient } from '../utils/zaphod/zaphod-client';

export default class ChannelsSwitchingService {
    private static instance: ChannelsSwitchingService;

    private constructor(
        private readonly client: ZaphodClient,
        private readonly deviceId: string,
    ) {}

    public static initWithClientAndDeviceId(
        client: ZaphodClient,
        deviceId: string,
    ) {
        ChannelsSwitchingService.instance = new ChannelsSwitchingService(
            client,
            deviceId,
        );
    }

    public static getInstance(): ChannelsSwitchingService {
        if (!ChannelsSwitchingService.instance) {
            throw new Error('ChannelsSwitchingService is not initialized');
        }
        return ChannelsSwitchingService.instance;
    }

    public switchTo(channelId: string, successCallback?: CallableFunction) {
        this.client
            .channelDetails(channelId, this.deviceId, STREAM_TYPE_TO_REQUEST)
            .then(response => {
                if (response) {
                    if (successCallback) {
                        successCallback(response);
                    }
                } else {
                    console.log('Invalid channel?');
                }
            })
            .catch(error =>
                console.log('Error while fetching channel details', error),
            );
    }

    public getNextChannelId(
        channels: ChannelInfo[],
        currentChannelId: string,
    ): string {
        if (channels.length === 0) {
            return '';
        }
        if (channels.length === 1) {
            return channels[0].id;
        }

        // Last channel is being watched, return very first channel's ID
        if (channels[channels.length - 1].id === currentChannelId) {
            return channels[0].id;
        }
        // Going up to the channel before the last one
        for (let i = 0; i < channels.length - 2; i++) {
            if (channels[i].id === currentChannelId) {
                return channels[i + 1].id;
            }
        }
        return channels[0].id;
    }

    public getPrevChannelId(
        channels: ChannelInfo[],
        currentChannelId: string,
    ): string {
        if (channels.length === 0) {
            return '';
        }
        if (channels.length === 1) {
            return channels[0].id;
        }
        // First channel is being watched, return very last channel's ID
        if (channels[0].id === currentChannelId) {
            return channels[channels.length - 1].id;
        }

        // Starting with the second element in an array
        for (let i = 1; i < channels.length; i++) {
            if (channels[i].id === currentChannelId) {
                return channels[i - 1].id;
            }
        }
        return channels[0].id;
    }
}
