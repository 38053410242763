import React, { useState } from 'react';
import { StyleSheet, TextInput, TouchableHighlight } from 'react-native';

interface PropsInterface {
    placeholder: string;
    onChangeText: CallableFunction;
    onSubmitEditing?: CallableFunction;
    secureTextEntry?: boolean;
    containerStyles?: any;
    value?: string;
    inputStyles?: {
        onBlur: any;
        onFocus: any;
    };
}

const InputControllableByRemote = ({
    secureTextEntry,
    onChangeText,
    onSubmitEditing,
    placeholder,
    containerStyles,
    inputStyles,
    value,
}: PropsInterface) => {
    const [inputValue, setInputValue] = useState<string>(value ? value : '');
    let inputRef: any = null;
    let containerRef: any = null;
    const componentInitialStyle =
        inputStyles && inputStyles.onBlur ? inputStyles.onBlur : {};

    const onFocus = () => {
        if (inputStyles && inputStyles.onFocus) {
            inputRef.setNativeProps({ style: inputStyles.onFocus });
            return;
        }
        inputRef.setNativeProps({ style: styles.inputFocused });
    };

    const onBlur = () => {
        if (inputStyles && inputStyles.onBlur) {
            inputRef.setNativeProps({ style: inputStyles.onBlur });
            return;
        }
        inputRef.setNativeProps({ style: styles.input });
    };

    return (
        <TouchableHighlight
            // activeOpacity={0.3}
            ref={ref => (containerRef = ref)}
            style={containerStyles}
            underlayColor={
                containerStyles.backgroundColor
                    ? containerStyles.backgroundColor
                    : ''
            }
            onFocus={onFocus}
            onBlur={onBlur}
            onPress={() => {
                inputRef.focus();
                console.log(`${placeholder} container onPress`);
            }}>
            <TextInput
                onFocus={onFocus}
                onBlur={onBlur}
                style={componentInitialStyle}
                ref={ref => (inputRef = ref)}
                placeholder={placeholder}
                secureTextEntry={!!secureTextEntry}
                onChangeText={text => {
                    setInputValue(text);
                    onChangeText(text);
                }}
                value={inputValue}
                onSubmitEditing={() => {
                    inputRef.blur();
                    if (onSubmitEditing) {
                        onSubmitEditing(inputValue);
                    }
                }}
            />
        </TouchableHighlight>
    );
};

const styles = StyleSheet.create({
    input: {},
    inputFocused: {},
});

export default InputControllableByRemote;
