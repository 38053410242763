import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideTopOverlay } from '../../state/actions';
import { colors, transparentColor } from '../../theming/colors';

const TopOverlay = ({ children }: any) => {
    const dispatch = useAppDispatch();
    const { bottomOverlayVisible, topOverlayVisible } = useAppSelector(
        state => state.ui,
    );

    return <View style={styles.overlayContainer}>{children}</View>;
};

const styles = StyleSheet.create({
    overlayContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        backgroundColor: colors.darkGrey,
        padding: 5,
    },
});

export default TopOverlay;
