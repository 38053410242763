import { createSlice } from '@reduxjs/toolkit';

interface UIState {
    topOverlayVisible: boolean;
    bottomOverlayVisible: boolean;
}

const initialState: UIState = {
    topOverlayVisible: false,
    bottomOverlayVisible: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showBottomOverlay: (state: UIState) => {
            state.bottomOverlayVisible = true;
        },
        hideBottomOverlay: (state: UIState) => {
            state.bottomOverlayVisible = false;
        },
        showTopOverlay: (state: UIState) => {
            state.topOverlayVisible = true;
        },
        hideTopOverlay: (state: UIState) => {
            state.topOverlayVisible = false;
        },
    },
});

export const {
    hideBottomOverlay,
    hideTopOverlay,
    showBottomOverlay,
    showTopOverlay,
} = uiSlice.actions;
export default uiSlice.reducer;
