import React, { useEffect } from 'react';
import { Keyboard, Pressable, StyleSheet, Text, View } from 'react-native';
import { colors } from '../theming/colors';

interface ButtonProps {
    onPress: CallableFunction;
    text?: string;
    containerStyles?: any;
    focusStyles?: any;
    children?: React.ReactNode;
    focus?: boolean;
}

const CustomButton = ({
    onPress,
    text,
    containerStyles,
    focusStyles,
    children,
    focus,
}: ButtonProps) => {
    let containerRef: any;

    const focusMeIfNeeded = () => {
        if (focus) {
            containerRef.setNativeProps({
                hasTVPreferredFocus: true,
                style: focusStyles,
            });
        } else {
            containerRef.setNativeProps({
                hasTVPreferredFocus: false,
                style: {
                    borderWidth: 0,
                },
            });
        }
    };

    useEffect(() => {
        focusMeIfNeeded();
    }, []);

    useEffect(() => {
        focusMeIfNeeded();
    }, [focus]);

    return (
        <Pressable
            style={StyleSheet.flatten([
                styles.container,
                containerStyles ? containerStyles : {},
            ])}
            ref={ref => (containerRef = ref)}
            onPress={() => {
                Keyboard.dismiss();
                onPress();
            }}>
            <View>
                {text && <Text style={styles.text}>{text}</Text>}
                {children}
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 100,
        borderRadius: 15,
        backgroundColor: colors.darkGrey,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
    },
    text: {
        color: colors.brightGrey,
    },
});

export default CustomButton;
