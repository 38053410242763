import { EPGItem } from '../../../services/epg';

export default class EPGResponseAdapter {
    public static adapt(response: any): EPGItem[] {
        if (!response || !response.epgs) {
            throw new Error('Invalid epgs reponse format');
        }

        return response.epgs.map((epgItem: any) => ({
            id: epgItem.id,
            image: epgItem.imageurl,
            name: epgItem.fullname,
            total: epgItem.total,
            data: epgItem.data.map((rawEpg: any) => ({
                id: `${rawEpg.contentid}`,
                title: rawEpg.title,
                description: rawEpg.description,
                image: rawEpg.imageurl,
                duration: rawEpg.duration,
                startTime: rawEpg.airingstarttime,
                endTime: rawEpg.airingendtime,
                season: rawEpg.season,
                link: rawEpg.weblink,
                episodeId: `${rawEpg.episodeid}`,
                pclevelepg: rawEpg.pclevelepg,
            })),
        }));
    }
}
