import React, { useEffect, useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ChannelsGrouppingService from '../../services/channels-groupping.service';
import ChannelsSwitchingService from '../../services/channels-switching.service';
import EPGService from '../../services/epg';
import EPGLayoutBuilder, { EPGLayout } from '../../services/epg-layout-builder';
import { hideTopOverlay, switchChannel } from '../../state/actions';
import { colors, transparentColor } from '../../theming/colors';
import DownButton from './DownButton';
import UpButton from './UpButton';

const SECONDS_FROM_PAST_TO_SHOW = 15 * 60; // 15 mins
const SECONDS_FROM_FUTURE_TO_SHOW = 1 * 60 * 60; // 2 hours
const LINE_HEIGHT = 40;
const IMAGE_HEIGHT = 30;
const HEADER_HEIGHT = 20;

const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
        date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    return `${hours}:${minutes}`;
};

const EPGNavigator = () => {
    const dispatch = useAppDispatch();
    const currentTime = Date.now();
    // const currentTime = 1624281219000;
    // const [currentTime, setCurrentTime] = useState<number>()
    const [layout, setLayout] = useState<EPGLayout>(
        EPGLayoutBuilder.emptyLayout(),
    );
    const { availableChannels, currentlyWatchedChannel } = useAppSelector(
        state => state.stream,
    );
    const [containerWidth, setContainerWidth] = useState<number>(-1);
    const [currentChannels, setCurrentChannels] = useState<string[]>([]);

    useEffect(() => {
        if (containerWidth <= 0) {
            return;
        }
        setCurrentChannels(
            ChannelsGrouppingService.pickNeighboursForAChannel(
                availableChannels,
                currentlyWatchedChannel.sources.id,
            ).map(channel => channel.channel.id),
        );
    }, [containerWidth]);

    useEffect(() => {
        if (currentChannels.length === 0) {
            return;
        }
        setLayout(
            new EPGLayoutBuilder(
                containerWidth,
                currentTime - SECONDS_FROM_PAST_TO_SHOW * 1000,
                currentTime + SECONDS_FROM_FUTURE_TO_SHOW * 1000,
            ).forChannelsAndTimeframe(
                EPGService.getInstance().getEpgsForChannels(currentChannels),
                currentChannels,
                currentTime,
            ),
        );
    }, [currentChannels]);

    useEffect(() => {
        if (layout.timelines.length === 0) {
            return;
        }
    }, [layout]);

    const channelSwitch = (channelId: string) => {
        dispatch(hideTopOverlay());
        ChannelsSwitchingService.getInstance().switchTo(
            channelId,
            (response: any) => {
                dispatch(switchChannel(response));
            },
        );
    };

    return (
        <View style={{ flexDirection: 'row' }}>
            <View
                key="epg-navigator-container"
                onLayout={event => {
                    setContainerWidth(event.nativeEvent.layout.width);
                }}
                style={{ flex: 1 }}>
                {layout.timelines.length === 0 && <Text>Building epg ...</Text>}
                {layout.timelines.length > 0 && (
                    <View
                        key="selected-channel-marker"
                        style={{
                            position: 'absolute',
                            zIndex: 1000,
                            borderWidth: 1,
                            borderColor: 'yellow',
                            left: 0,
                            right: 0,
                            top: HEADER_HEIGHT + 2 * LINE_HEIGHT,
                            height: LINE_HEIGHT + 2,
                        }}>
                        <Pressable
                            key="channel-switch-pressable-area"
                            style={{
                                height: '100%',
                                width: 40,
                            }}
                            onPress={() => {
                                channelSwitch(currentChannels[2]);
                            }}></Pressable>
                    </View>
                )}

                {layout.timelines.length > 0 && (
                    <View style={styles.epgContainer}>
                        <View
                            key="epg-channels-switch-buttons-container"
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: 25,
                                bottom: 0,
                                display: 'flex',
                                backgroundColor: '#43454C',
                                zIndex: 3000,
                            }}></View>
                        <View
                            style={{
                                overflow: 'hidden',
                                position: 'absolute',
                                left:
                                    layout.summary.currentTimeMarkerOffset - 26,
                                height: LINE_HEIGHT,
                            }}>
                            <Text style={styles.currentTimeBadge}>
                                {formatDate(currentTime)}
                            </Text>
                        </View>
                        <View
                            key="epg-current-time-marker"
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                left: 40,
                                bottom: 0,
                                borderRightColor: 'red',
                                borderRightWidth: 1,
                                backgroundColor: transparentColor('black', 0.6),
                                top: HEADER_HEIGHT,
                                right:
                                    containerWidth -
                                    layout.summary.currentTimeMarkerOffset,
                            }}></View>
                        <View
                            style={{
                                top: HEADER_HEIGHT,
                                position: 'absolute',
                                left: -layout.summary.layoutLeftsideOffset,
                            }}>
                            {layout.timelines.map(timeline => {
                                const image =
                                    EPGService.getInstance().imageForChannel(
                                        timeline.channelId,
                                    );
                                return (
                                    <View
                                        key={timeline.channelId}
                                        style={{
                                            flexDirection: 'row',
                                        }}>
                                        <View
                                            style={{
                                                position: 'absolute',
                                                zIndex: 3000,
                                                backgroundColor:
                                                    colors.darkGrey,
                                                top: 0,
                                                bottom: 0,
                                                paddingLeft: 5,
                                                paddingRight: 10,
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                left: layout.summary
                                                    .layoutLeftsideOffset,
                                            }}>
                                            <Pressable
                                                onPress={() => {
                                                    channelSwitch(
                                                        timeline.channelId,
                                                    );
                                                }}>
                                                <Image
                                                    style={{
                                                        width: IMAGE_HEIGHT,
                                                        height: IMAGE_HEIGHT,
                                                        resizeMode: 'contain',
                                                    }}
                                                    source={{
                                                        uri: image.src,
                                                    }}></Image>
                                            </Pressable>
                                        </View>

                                        {timeline.timeslots.map(slot => {
                                            return (
                                                <View
                                                    key={slot.payload.id}
                                                    style={{
                                                        paddingRight: 2,
                                                        paddingBottom: 5,
                                                        paddingTop: 5,
                                                        height: LINE_HEIGHT,
                                                        width: slot.width,
                                                    }}>
                                                    <View
                                                        style={{
                                                            ...(slot.payload
                                                                .startTime <=
                                                                currentTime &&
                                                            slot.payload
                                                                .endTime >
                                                                currentTime
                                                                ? {
                                                                      backgroundColor:
                                                                          transparentColor(
                                                                              'kpnGreen',
                                                                              0.2,
                                                                          ),
                                                                  }
                                                                : {
                                                                      backgroundColor:
                                                                          transparentColor(
                                                                              'black',
                                                                              0.5,
                                                                          ),
                                                                  }),
                                                            borderRadius: 4,
                                                            paddingLeft: 3,
                                                            paddingRight: 3,
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'center',
                                                        }}>
                                                        <Text
                                                            numberOfLines={1}
                                                            style={{
                                                                color: colors.white,
                                                                borderRadius: 4,
                                                                paddingLeft: 3,
                                                                paddingRight: 3,
                                                                flex: 1,
                                                                overflow:
                                                                    'hidden',
                                                                flexDirection:
                                                                    'column',
                                                                justifyContent:
                                                                    'center',
                                                                textAlign:
                                                                    'right',
                                                            }}>
                                                            {slot.payload.title}
                                                        </Text>
                                                        <Text
                                                            numberOfLines={1}
                                                            style={{
                                                                paddingLeft: 3,
                                                                paddingRight: 3,
                                                                fontSize: 10,
                                                                color: colors.white,
                                                                textAlign:
                                                                    'right',
                                                            }}>
                                                            {`${formatDate(
                                                                slot.payload
                                                                    .startTime,
                                                            )}`}
                                                            -
                                                            {`${formatDate(
                                                                slot.payload
                                                                    .endTime,
                                                            )}`}
                                                        </Text>
                                                    </View>
                                                </View>
                                            );
                                        })}
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                )}
            </View>
            <View style={{ paddingTop: 22 }}>
                <UpButton
                    onPress={() => {
                        setCurrentChannels(
                            ChannelsGrouppingService.pickNeighboursForAChannel(
                                availableChannels,
                                currentChannels[1],
                            ).map(channel => channel.channel.id),
                        );
                    }}
                />
                <View style={{ flex: 1 }}></View>
                <DownButton
                    onPress={() => {
                        setCurrentChannels(
                            ChannelsGrouppingService.pickNeighboursForAChannel(
                                availableChannels,
                                currentChannels[3],
                            ).map(channel => channel.channel.id),
                        );
                        console.log('Down');
                    }}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    epgContainer: {
        position: 'relative',
        overflow: 'hidden',
        height: 5 * LINE_HEIGHT + HEADER_HEIGHT,
        borderWidth: 1,
        borderColor: 'transparent',
    },
    currentTimeMarker: {
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        left: 0,
        borderRightColor: 'red',
        borderRightWidth: 1,
        bottom: 0,
        backgroundColor: transparentColor('black', 0.6),
    },
    slotContainer: {
        paddingRight: 2,
        paddingBottom: 5,
        paddingTop: 5,
        height: LINE_HEIGHT,
    },
    slotItem: {
        borderRadius: 4,
        paddingLeft: 3,
        paddingRight: 3,
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    currentlyNotWatched: {
        backgroundColor: transparentColor('black', 0.5),
    },
    currentlyWatched: {
        backgroundColor: transparentColor('kpnGreen', 0.2),
    },
    imageContainer: {
        position: 'absolute',
        zIndex: 2000,
        backgroundColor: colors.darkGrey,
        top: 0,
        bottom: 0,
        paddingLeft: 5,
        paddingRight: 10,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    currentChannelSelection: {
        position: 'absolute',
        zIndex: 2000,
        borderWidth: 1,
        borderColor: 'yellow',
        left: 0,
        right: 0,
    },
    highlightTextColor: {
        color: colors.white,
    },
    textColor: {
        color: colors.white,
    },
    currentTimeBadge: {
        paddingLeft: 5,
        paddingRight: 5,
        color: colors.white,
        backgroundColor: 'red',
        borderRadius: 5,
    },
    timeStamp: {
        paddingLeft: 3,
        paddingRight: 3,
        fontSize: 10,
    },
});

export default EPGNavigator;
