import React from 'react';
import { Image, Text, View } from 'react-native';
import FullscreenOverlayWithCenteredContent from '../../components/FullscreenOverlay';
import KPNLogo from '../../components/KPNLogo';
import { colors } from '../../theming/colors';

interface LoadingBadgeProps {
    channel: string;
}

const ChannelLoadingBadge = ({ channel }: LoadingBadgeProps) => {
    return (
        <FullscreenOverlayWithCenteredContent>
            <View
                style={{
                    backgroundColor: colors.darkGrey,
                    padding: 10,
                    borderRadius: 5,
                }}>
                <Text style={{ color: colors.white }}>
                    Switching to {channel}...
                </Text>
            </View>
        </FullscreenOverlayWithCenteredContent>
    );
};

export default ChannelLoadingBadge;
