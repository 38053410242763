import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ChannelDetails,
    ChannelDetailsFactory,
    ChannelInfo,
} from '../../utils/zaphod/content.interface';

interface StreamState {
    currentlyWatchedChannel: ChannelDetails;
    availableChannels: ChannelInfo[];
}
const initialState: StreamState = {
    currentlyWatchedChannel: ChannelDetailsFactory.unknownChannel(),
    availableChannels: [],
};

const streamSlice = createSlice({
    name: 'stream',
    initialState,
    reducers: {
        switchChannel: (state, action: PayloadAction<ChannelDetails>) => {
            state.currentlyWatchedChannel = action.payload;
        },
        loadChannels: (state, action: PayloadAction<ChannelInfo[]>) => {
            state.availableChannels = action.payload;
        },
    },
});

export const { switchChannel, loadChannels } = streamSlice.actions;
export default streamSlice.reducer;
