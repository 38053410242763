import { configureStore } from '@reduxjs/toolkit';
import authReducer from './store/auth';
import debugConsoleReducer from './store/debug-console';
import streamReducer from './store/stream';
import uiReducer from './store/ui';

const store = configureStore({
    reducer: {
        auth: authReducer,
        stream: streamReducer,
        debug: debugConsoleReducer,
        ui: uiReducer,
    },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
