import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import * as shaka from 'shaka-player';

interface ZaphodShakaPlayerProps {
    src: string;
    muted: boolean;
    fullscreen: boolean;
    drm: any;
}

const ZaphodShakaPlayer = ({
    src,
    muted,
    fullscreen,
    drm,
}: ZaphodShakaPlayerProps) => {
    const videoRef = React.useRef(null);
    const [player, setPlayer] = useState(null);

    useEffect(() => {
        const playerInstance = new shaka.Player(videoRef.current);
        setPlayer(playerInstance);

        return () => {
            playerInstance.destroy();
        };
    }, []);

    useEffect(() => {
        if (player && src) {
            if (drm && drm.widevine) {
                player.configure({
                    drm: {
                        servers: {
                            'com.widevine.alpha':
                                drm.widevine.licenseAcquisitionURL,
                        },
                    },
                });
                player.load(src);
            } else {
                player.load(src);
            }
        }
    }, [player, src]);

    useEffect(() => {
        if (player && fullscreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen(error => {
                    console.log('Fullscreen error');
                });
                return;
            }
            if (videoRef.current.webkitEnterFullScreen) {
                videoRef.current.webkitEnterFullScreen();
                return;
            }
        }
    }, [fullscreen]);

    return (
        <video
            ref={videoRef}
            id="web-video"
            width="100%"
            height="100%"
            autoPlay
            muted={muted}
            controls></video>
    );
};

export default ZaphodShakaPlayer;
