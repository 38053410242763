import React from 'react';
import { View } from 'react-native';

interface OverlayProps {
    background?: string;
    children: React.ReactNode;
}

const FullscreenOverlayWithCenteredContent = ({
    children,
    background,
}: OverlayProps) => {
    return (
        <View
            style={{
                ...{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                },
                ...(background ? { backgroundColor: background } : {}),
            }}>
            {children}
        </View>
    );
};
export default FullscreenOverlayWithCenteredContent;
