import { Platform } from 'react-native';
import { getAndroidId } from 'react-native-device-info';
import * as uuid from 'uuid';

export default class DeviceIdService {
    private static readonly DEVICE_ID_STORAGE_KEY = 'zDeviceId';
    public static async getDeviceId(): Promise<string> {
        if (Platform.OS === 'web') {
            return DeviceIdService.getWebId();
        }

        console.log('Using Android id');
        return await getAndroidId();
    }

    private static getWebId(): string {
        console.log('Using web ID');
        const storageKey = localStorage.getItem(
            DeviceIdService.DEVICE_ID_STORAGE_KEY,
        );
        if (storageKey) {
            return storageKey;
        }

        const id = uuid.v4();
        localStorage.setItem(DeviceIdService.DEVICE_ID_STORAGE_KEY, id);
        return id;
    }
}
