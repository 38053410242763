import { StyleSheet } from 'react-native';
import { colors } from './colors';

const defaultStyles = StyleSheet.create({
    inputSizing: {
        height: 40,
        borderRadius: 5,
        borderWidth: 1,
        marginBottom: 5,
        marginTop: 10,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
    },
    defaultInputContainer: {
        width: 200,
        backgroundColor: colors.black,
    },
    defaultInput: {
        backgroundColor: colors.darkGrey,
        color: colors.brightGrey,
        borderColor: colors.darkGrey,
    },

    defaultInputFocused: {
        backgroundColor: colors.grey,
        color: colors.white,
        borderColor: colors.brightGrey,
    },
});

export default defaultStyles;
